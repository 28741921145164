
.owl-carousel-play .owl-carousel {
	margin: 0;
	border: none;
}

.products-block {
	.owl-carousel {
		margin: 0;
	}
}

.price {
	font-weight: 400;
	font-family: $hf;
	.price-new {
		color: $bk;
		font-size: 22px;
	}
	.price-old {
		text-decoration: line-through;
		color: $mg;
		font-size: 18px;
	}
	&.detail {
		.price-new {
			font-size: 40px;
		}
		.price-old {
			font-size: 26px;
		}
		ul {
			margin: 0;
		}
	}
}

.cheap_invoice {
	a {
		font-size: 16px;
		margin-bottom: 5px;
		text-decoration: underline dotted;
		display: block;
		color: #444;
		&:hover {
			color: $bk;
		}
	}
}

.product-block {
	position: relative;
	background-color: $white;
	border-top: 1px solid transparent;
	@include rounded-corners(3px);
	.description {
		display: none;
	}
	.product-meta {
		line-height: 25px;
		padding: 0;
	}
	.bottom {
		display: block;
	}
	.price {
		text-align: center;
	}
	.name {
		margin: 10px 0;
		font-size: $font-size-base;
		font-family: $font-family-base;
		text-transform: inherit;
		overflow: hidden;
		font-weight: 600;
		a {
			color: $black;
			padding: 5px 0;
			&:hover {
				color: $bk;
			}
			span {
				text-align: center;
				display: block;
				&.title1 {
					font-weight: 400;
				}
				&.title2 {
					font-family: $hf;
					font-weight: 500;
					font-size: 18px;
					line-height: 26px;
				}
			}
		}
		.rating {
			font-size: 14px;
			margin-top: 7px;
			text-align: center;
		}
	}
	.image {
		position: relative;
		overflow: hidden;
		@include transition(all 0.4s ease 0s);
		padding: 10px 10px 0 10px;
	}
	.top {
		position: relative;
		padding: 0 20px 10px 20px;
	}
}

.cart {
	position: relative;
	@include transition(all 0.4s ease 0s);
	padding-bottom: 15px;
	.icon-cart {
		display: block;
		@include size(16px, 17px);
	}
}

.product-label {
	font-size: $font-size-base - 2;
	font-weight: bold;
	text-transform: uppercase;
	z-index: 99;
	position: absolute;
	text-align: center;
	border: 1px solid $tc;
	line-height: 17px;
	padding: 5px 15px;
	top: 7px;
	&.sale {
		background-color: $theme-color;
		left: 7px;
		color: $wh;
	}
	&.new {
		right: 7px;
		background-color: $wh;
		color: $tc;
	}
}

.cart.pull-left {
	float: none !important;
	margin-bottom: 5px;
}

.product-info .tab-content {
	border: none;
	padding: 0;
	margin-bottom: 30px;
	.desc-header-wrapper {
		border: 1px $tc solid;
		margin-top: 30px;
		margin-bottom: 15px;
		.desc-header {
			padding: 15px;
			color: $tc;
			text-transform: uppercase;
			font-size: 18px;
			position: relative;
			top: 1px;
			display: inline-block;
		}
	}
	#tab-description {
		padding: 0 15px;
	}
	#tab-specification {
		td {
			padding: 10px 15px !important;
			color: $bk !important;
		}
	}
	.owl-related {
		.owl-next, .owl-prev {
			top: -57px;
		}
		.owl-next {
			right: 18px;
		}
		.owl-prev {
			right: 48px;
		}
		.owl-stage {
			padding: 0;
		}
		.product-block {
			width: 100%;
			margin: 0;
			.image {
				border: none;
			}
		}
	}
	.widget-products {
		margin: 0 -2px;
	}
	.scroll-button {
		margin-bottom: 5px;
	}
	.product-grid.no-scroll {
		 .product-block {
			 margin: 4px 2px;
			 width: calc(100% / 4 - 7px);
			 .image {
				 border: 0;
			 }
		 }
	}
}

.cheap_invoice {
	margin-bottom: 15px;
}

.storage_widget {
	border-top-color: $bc;
	& > span:nth-child(2) {
		color: $theme-color !important;
		@include transition(all .2s ease-in-out);
	}
	table tr {
		td:last-child span {
			background: $theme-color !important;
			border-radius: 0 !important;
			&:hover {
				background: $bk !important;
			}
		}
	}
}

.product-markdown-view {
	#product {
		.price {
			margin: 0;
		}
		.price-old, .price-new {
			line-height: 1;
		}
		.cart-onclick {
			a {
				margin: 0;
			}
		}
	}
	#button-cart,
	.other-dost2 {
		margin: 0;
	}

	#product, .product-meta, .cart, .other-dost, .cheaper-products, .cheaper-items, .cheaper-item, .cheaper-item-info {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
	.other-dost {
		gap: 8px;
	}

	.general, .cheaper-products {
		&__title {
			font-size: 18px;
			font-weight: 600;
		}
		&__reason, .cheaper-item__reason {
			color: $mg;
		}
	}
	.general {
		margin-bottom: 20px;

		.quantity {
			padding-bottom: 0;
		}
	}

	.cheaper-item {
		padding: 20px;
		border: 1px solid $mg;

		&__status {
			color: $mg;
			font-weight: 600;
		}
		&__price {
			display: flex;
			align-items: flex-end;
			gap: 16px;
			flex-wrap: wrap;

			.price-new {
				font-size: 22px;
			}
			.price-old {
				font-size: 16px;
			}
			.price-old, .price-new {
				line-height: 1;
			}
		}
		&:hover {
			text-decoration: none;
		}
	}
}