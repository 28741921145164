.buttons {
	@include clearfix;
	padding: 20px 0;
	.left {
		float: left;
	}
	.right {
		float: right;
	}
}

.checkbox, .radio {
	padding-left: 20px;
}

.btn {
	line-height: 36px;
	padding: 0 10px;
	@include transition (all 200ms ease 0.2s);
	@include rounded-corners(0);
}

.btn-outline-inverse {
	@include button-variant-outline($gray-dark, $white, #f2f2f2, $white, $brand-success, #f2f2f2);
	border: none;
	border-right: 1px solid #f2f2f2;
	&.last {
		border-right: none;
	}
}

.btn-primary {
	@include button-variant-outline($wh, $tc, $tc, $wh, $dg, $dg);
}

.btn-default {
	@include button-variant-outline($wh, $dg, $dg, $wh, $tc, $tc);
}

.btn-white {
	@include button-variant-outline($tc, $wh, $tc, $dg, $wh, $dg);
}

.btn-credit {
	margin-left: 15px;
	@include button-variant-outline($dg, $lg, $lg, $lg, $dg, $dg);
}

.btn-outline-success {
	padding: 15px 25px;
	line-height: 13px;
}

.btn-link {
	font-size: $font-size-base - 2;
	text-transform: lowercase;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	padding-bottom: 2px;
}