button {
	&:active, &:focus {
		outline: none !important;
	}
}

#page {
	min-height: 100vh;
	& > .container {
		min-height: calc(100vh - 448px);
	}
}

.page-themecontrol-product {
	#page {
		min-height: 1px;
	}
}

.main-title {
	position: absolute;
	left: -9999rem;
	top: -9999rem;
}

#button-cart {
	width: 122px;
}

.product-info {
	margin-top: 24px;
}

#tab-description {
	img {
		height: auto;
	}
}

#tab-downloads {
	ul li {
		list-style: none;
	}
}

#mainmenutop {
	.nav.navbar {
		height: 60px;
	}
	.panel-v4 .panel-body {
		border: none !important;
	}
}
.advantages img, .advantages .col-sm-9 {
	height: auto !important;
}
#is_ip .radio_custom .input-control {
	margin-right: 15px;
}
.modal-dialog {
	.reserve_button, .callback_button {
		@include rounded-corners(0);
		background: $tc;
		@include transition(0.2s all);
		padding: 10px 15px;
		text-transform: uppercase;
		font-family: $font-family-base;
		&:hover {
			background: $black;
		}
	}
	.reserve_button {
		padding: 0px 20px;
	}
}

.contact-icon {
	display: inline-block;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background: $tc;
	top: 0;
	position: absolute;
	i.fa {
		color: $white;
		font-size: 30px;
		padding: 11px 10px 10px 10px;
		width: 50px;
		height: 50px;
		text-align: center;
		margin-top: -1px;
	}
}

.contact-text {
	display: inline-block;
	padding-left: 60px;
	min-height: 60px;
	margin-bottom: 10px;
	line-height: 24px;
	div.contact-text-b-days div {
		display: inline-block;
		&:first-child {
			width: 176px;
		}
	}
}

.search_block {
	overflow: visible;
	form {
		margin: 0;
		button {
			border: 1px solid $wh;
			padding: 0 8px;
			height: 36px;
			&:focus, &:active {
				outline: none !important;
			}
			i {
				font-size: 23px;
				vertical-align: -2px;
			}
		}
	}
	.dropdown-menu.autosearch {
		margin: 0;
		width: 350px;
		min-width: 0;
		padding: 0;
		right: 0;
		left: auto;
		display: none;
		border-radius: 0;
		top: 100%;
		li {
			@include transition(0.2s all);
			display: inline-block;
			width: 100%;
			margin-bottom: 5px;
			cursor: pointer;
			line-height: 1;
			position: relative;
			border-bottom: 1px #ebebeb solid;
			&:nth-last-child(-n+2) {
				border: none;
			}
			> div {
				line-height: 1.5;
				display: inline-block;
			}
			a {
				white-space: normal;
				padding: 3px 5px 20px 0;
			}
			.as-image {
				float: left;
				padding: 5px;
				width: 80px;
				margin-right: 10px;
				img {
					height: auto;
					width: 100%;
				}
			}
			.as-title {
				width: 100%;
				font-size: 16px;
				margin: 10px 0;
				a {
					color: $black;
				}
			}
			.as-price {
				width: calc(100% - 95px);
				font-size: 14px;
				font-weight: 600;
				position: absolute;
				bottom: 10px;
				left: 95px;
				.price-old {
					font-size: 14px;
					text-decoration: line-through;
					margin-right: 7px;
					color: #777;
				}
			}
			#showall {
				padding: 0 25px;
				display: inline-block;
			}
		}
		@media (max-width: 1199px) {
			width: 300px;
			li {
				.as-image {
					width: 80px;
				}
				.as-title {
					width: 100%;
					font-size: 14px;
					font-weight: 500;
					line-height: 16px;
				}
				.as-price {
					width: calc(100% - 120px);
					left: 95px;
				}
			}
		}
		@media (max-width: 767px) {
			width: 260px;
			right: -15px;
			left: auto;
		}
		@media (max-width: 421px) {
			top: calc(100% + 2px);
			width: calc(100vw - 30px);
			left: auto;
		}
	}
}

.producttabs {
	margin-top: 30px;
	margin-bottom: 0;
}

#checkout-form #shipping_methods .radio, #checkout-form #payment_methods .radio {
	margin-left: 0 !important;
}

.radio_custom {
	.list_of_points {
		display: block;
		text-align: left;
		font-size: 14px;
		color: $bk;
		font-weight: 500;
		padding-left: 22px;
	}
	a {
		padding-left: 10px;
		font-size: 18px;
		color: $tc;
	}
	.input-control {
		cursor: pointer;
		display: inline-block;
		padding-left: 22px;
		position: relative;
		font-size: 14px;
		font-weight: 500;
		color: $bk;
		margin: 1px 0;
		input {
			position: absolute;
			width: auto !important;
			z-index: 1;
			opacity: 0;
		}
		&__indicator {
			border-radius: 50%;
			display: block;
			position: absolute;
			top: 4px;
			left: 0;
			user-select: none;
			@include size(15px, 15px);
			text-align: center;
			background-color: $tc;
		}
		&.radio input ~ .input-control__indicator:after {
			border-radius: 50%;
			content: "";
			display: block;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			transform: scale(0.8);
			transform-origin: center center;
			width: 100%;
			background: $tc;
			@include opacity(0);
			@include transition(.1s opacity linear);
			border: 3px $white solid;
		}
		&.radio input:checked ~ .input-control__indicator:after {
			@include opacity(1);
		}
	}
	@media (max-width: 767px) {
		.input-control, .list_of_points {
			font-size: 14px;
		}
	}
}

.checkbox_custom {
	a {
		padding-left: 10px;
		font-size: 14px;
		color: $tc;
		&.agree b {
			font-weight: 500;
			padding: 0;
			font-size: 12px;
		}
	}
	.input-control {
		cursor: pointer;
		display: inline-block;
		padding-left: 25px;
		line-height: 16px;
		position: relative;
		font-size: 12px;
		font-weight: 500;
		color: $bk;
		margin: 5px 10px 5px 14px;
		text-align: left;
		input {
			position: absolute;
			width: auto !important;
			z-index: 1;
			opacity: 0;
		}
		&__indicator {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			user-select: none;
			@include size(16px, 16px);
			text-align: center;
			background-color: $white;
			border: 2px $tc solid;
			@include rounded-corners(0px);
		}
		&.checkbox input ~ .input-control__indicator:after {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 4px;
			transform-origin: center center;
			@include opacity(0);
			@include transition(.1s opacity linear);
			@include transform(translateZ(1px) rotate(45deg));
			border-bottom: $tc 2px solid;
			width: 5px;
			height: 10px;
			border-right: $tc 2px solid;
		}
		&.checkbox input:checked ~ .input-control__indicator:after {
			@include opacity(1);
		}
	}
}

#button-order {
	margin-top: 15px;
}

.category_widget {
	a {
		.type1 {
			height: 500px;
			background: transparent center no-repeat;
			background-size: cover;
			margin-bottom: 30px;
			position: relative;
			h2 {
				margin: 0;
				position: absolute;
				height: 70px;
				left: 0;
				right: 0;
				bottom: 0;
				background: rgba(0, 0, 0, .7);
				font-weight: 600;
				text-align: center;
				text-transform: uppercase;
				color: $white;
				font-size: 20px;
				padding: 23px 0;
				display: inline-block;
			}
		}
	}
}

.product-compare {
	.comparetable {
		.img-thumbnail {
			border: none;
		}
		.table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
			width: 500px;
		}
	}
	.compare_actions {
		.btn {
			width: 100px;
		}
	}
}

#totop {
	position: fixed;
	right: 10px;
	bottom: 10px;
	cursor: pointer;
	width: 40px;
	height: 40px;
	background-color: $tc;
	text-indent: -9999px;
	z-index: 999;
	border: 1px $white solid;
	@include transition(200ms all ease-in-out);
	span {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -8px;
		margin-top: -12px;
		height: 0;
		width: 0;
		border: 8px solid transparent;
		border-bottom-color: #ffffff;
	}
	&:hover {
		background-color: $bk;
	}
}

#coupon_form {
	h4 {
		font-size: 16px;
	}
	#button-coupon {
		padding: 0 16px;
	}
}

.maintenance {
	min-height: calc(100vh - 381px);
}

.widget-products {
	.product-grid .product-block {
		margin: 5px;
		width: auto;
	}
}

h1 {
	padding: 15px 0;
	text-transform: none;
}

.comparetable {
	padding: 15px;
	margin-bottom: 15px;
	overflow-x: auto;
	table {
		margin-bottom: 0;
	}
	.price-old {
		text-decoration: line-through;
		color: $mg;
	}
}

.owl-prev, .owl-next {
	position: absolute;
	top: -33px;
	width: 25px;
	height: 25px;
	color: #fff;
	@include transition(0.2s ease all);
	background: $tc center no-repeat;
	font-family: $fa;
	font-size: 22px;
	line-height: 1;
	padding: 1px;
	&:hover {
		background-color: $mg;
	}
}

#image-additional-carousel {
	padding: 0 30px;
}

#image-additional {
	.owl-prev, .owl-next {
		top: 13px;
	}
	.owl-next {
		right: 0;
	}
	.owl-prev {
		left: 0;
		right: auto;
	}
}

.owl-prev {
	right: 31px;
	&:before {
		content: '\f111';
	}
}

.owl-next {
	right: 3px;
	&:before {
		content: '\f112';
	}
}

.mfilter-box {
	.box-heading {
		display: none;
	}
	.box-content {
		border: none;
		.mfilter-heading {
			background: transparent;
			border: none;
			.mfilter-heading-content {
				padding: 0 0 6px 0;
			}
			.mfilter-head-icon {
				@include size(25px, 20px);
				@include transition(none);
				@include notransform();
				font-size: 20px;
				line-height: 1;
				font-family: $fa;
				background: none;
				font-style: normal;
				&:before {
					content: "\f2c2";
				}
			}
			&.mfilter-collapsed .mfilter-head-icon {
				&:before {
					content: "\f28e";
				}
			}
		}
		.mfilter-heading-text {
			width: calc(100% - 27px);
			margin-left: 0;
			> span {
				padding-left: 0;
				font-size: 16px;
				font-family: $hf;
				text-transform: uppercase;
			}
		}
		.mfilter-col-input {
			padding-right: 8px;
		}
		.mfilter-opts-container {
			border-top: 1px solid $lg;
			padding: 9px 0 5px 0;
			label {
				font-weight: 400;
			}
			.mfilter-counter {
				border-radius: 0;
				background: #acacac;
				&:after {
					content: none;
				}
				&.mfilter-close {
					background: url(/catalog/view/theme/default/stylesheet/mf/images/icons.png?v7) no-repeat 0 0;
				}
			}
			input[type="text"] {
				border-radius: 0;
				height: 45px;
			}
			.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
				background: $white;
			}
			.mfilter-slider-slider, #mfilter-price-slider {
				border: $lg 1px solid;
				background: $white;
				margin: 15px 2px 0 5px !important;
				.ui-slider-range {
					background: $lg !important;
				}
				.ui-slider-handle {
					background: $white !important;
					height: 18px !important;
					border: 1px $lg solid !important;
					border-radius: 0;
				}
			}
		}
	}
	.mfilter-tb-as-td {
		vertical-align: bottom;
		font-size: 12px;
	}
}

.selectric-wrapper {
	position: relative;
	cursor: pointer;
}

.selectric-responsive {
	width: 100%;
}

.selectric {
	border: 1px solid $lg;
	border-radius: 0;
	background: $white;
	position: relative;
	overflow: hidden;
	.label {
		display: block;
		white-space: nowrap;
		overflow: visible;
		width: calc(100% - 35px);
		text-overflow: ellipsis;
		margin: 0 25px 0 10px;
		padding: 8px 0;
		font-size: 14px;
		color: $black;
		height: 30px;
		font-weight: 400;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		line-height: 1;
	}
	.button {
		display: block;
		position: absolute;
		right: 0;
		top: 0;
		width: 38px;
		line-height: 38px;
		background-color: $white;
		color: $black;
		text-align: center;
		&:after {
			content: '';
			position: absolute;
			@include size(6px, 6px);
			border-bottom: 1px solid $black;
			border-right: 1px solid $black;
			@include transform(rotate(45deg));
			right: 10px;
			top: 10px;
		}
	}
}

.selectric-focus .selectric {
	border-color: #aaaaaa;
}

.selectric-hover .selectric {
	.button {
		color: #a2a2a2;
		&:after {
			border-top-color: #a2a2a2;
		}
	}
}

.selectric-open {
	z-index: 9999;
	.selectric .button::after {
		@include transform(rotate(225deg));
		transform-origin: 4px 5px;
	}
	.selectric-items {
		display: block;
	}
}

.selectric-disabled {
	filter: alpha(opacity=50);
	opacity: 0.5;
	cursor: default;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.selectric-hide-select {
	position: relative;
	overflow: hidden;
	width: 0;
	height: 0;
	select {
		position: absolute;
		left: -100%;
	}
	&.selectric-is-native {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 10;
		select {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			height: 100%;
			width: 100%;
			border: none;
			z-index: 1;
			box-sizing: border-box;
			opacity: 0;
		}
	}
}

.selectric-input {
	position: absolute !important;
	top: 0 !important;
	left: 0 !important;
	overflow: hidden !important;
	clip: rect(0, 0, 0, 0) !important;
	margin: 0 !important;
	padding: 0 !important;
	width: 1px !important;
	height: 1px !important;
	outline: none !important;
	border: none !important;
	*font: 0/0 a !important;
	background: none !important;
}

.selectric-temp-show {
	position: absolute !important;
	visibility: hidden !important;
	display: block !important;
}

.selectric-items {
	display: none;
	position: absolute;
	top: 31px;
	right: 0;
	background: $white;
	border: 1px solid $lg;
	z-index: -1;
	box-shadow: 0 0 10px -6px;
	.selectric-scroll {
		height: 100%;
		overflow: auto;
	}
	ul, .selectric-items li {
		list-style: none;
		padding: 0;
		margin: 0;
		font-size: 14px;
		line-height: 20px;
		min-height: 20px;
	}
	li {
		display: block;
		padding: 10px;
		color: $black;
		cursor: pointer;
		line-height: 16px;
		white-space: nowrap;
		&.selected, &.highlighted, &:hover {
			color: $wh;
			background-color: $tc;
		}
	}
	.disabled {
		filter: alpha(opacity=50);
		opacity: 0.5;
		cursor: default !important;
		background: none !important;
		color: #666 !important;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}
	.selectric-group {
		.selectric-group-label {
			font-weight: bold;
			padding-left: 10px;
			cursor: default;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			background: none;
			color: #444;
		}
		&.disabled li {
			filter: alpha(opacity=100);
			opacity: 1;
		}
	}
}

.selectric-above .selectric-items {
	top: auto;
	bottom: 100%;
}

.widget-products {
	.tab-header {
		padding: 5px 0;
		border-bottom: $bc 1px solid;
		span {
			font-family: $hf;
			padding: 0 5px;
			font-size: 18px;
			text-transform: uppercase;
		}
	}
	.owl-stage {
		padding: 15px 0;
	}
	.owl-item {
		&:hover {
			z-index: 400;
		}
	}
}

#request-on-order-form .btn-buy-product {
	padding: 0 37px !important;
	color: $white !important;
	@include transition(0.2s all);
	&:hover {
		background: $bk !important;
	}
}

.page-information, .checkout-buy {
	h1 {
		margin-top: 0;
	}
}

.pagescarousel {
	margin: 30px 0;
	.item {
		padding: 0 47px
	}
	h3 {
		margin: 0 0 15px 0;
	}
	.owl-next, .owl-prev {
		top: calc(50% - 20px);
	}
	.owl-prev {
		left: 0;
	}
	.owl-stage {
		display: flex;
		align-items: stretch;
	}
	.owl-item {
		position: relative;
	}
	.pc_wrapper {
		display: flex;
		& > div {
			&.pc_image, &.pc_text {
				flex: 0 0 50%;
			}
			&.pc_text {
				padding-right: 15px;
				p {
					text-align: justify;
					padding-bottom: 46px;
				}
				.btn {
					position: absolute;
					bottom: 0;
				}
			}
			&.pc_image {
				padding-left: 15px;
			}
		}
	}
}

.holiday_works {
	text-align: center;
	border: none !important;
	margin: 0 !important;
	display: block;
	color: red !important;
	position: absolute;
	left: 50px;
	bottom: -20px;
}

.quick-view {
	#content {
		padding: 0;
	}
}

#review {
	table {
		margin-bottom: 15px;
	}
	.rating {
		margin-left: 15px;
	}
}

#form-review-container {
	.recaptcha_container label {
		display: none;
	}
}

.rating, .stars {
	.fa {
		color: #ffbb00;
	}
}

.prod_rating {
	.custom-color {
		cursor: pointer;
	}
}

#footer {
	.ft-wt {
		display: flex;
		flex-wrap: wrap;
		span:last-child {
			letter-spacing: 0.4px;
		}
	}
	.ft-phone {
		span:first-child {
			letter-spacing: 0.4px
		}
	}
}
.payment2row {
	display: none;
	margin: 8px auto;
}

@media screen and (max-width: 1395px) {
	#compare-name, #compare-number-name, #cart-number-count, #cart-summa-count {display:none !important;}
	.headercart {flex: 0 1 200px !important;}
	.main-menu {width:85% !important;}
}

@media screen and (max-width: 1200px) {
	#cart-summa-count {display:none !important;}
	.headercart {flex: 0 1 150px !important;}
}